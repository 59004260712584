import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Container, Text, Button, Dropdown, Loading, Popover, Input } from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { getProductBatch } from "../../api/dashboard";
import { useSnackbar } from "notistack";
import { formatCategoryName, fuzzySearch } from "../../utils/helpers";
import { useFilter } from "../../context/FilterContext";
import { DatePicker } from "@mantine/dates";

interface HeaderProps {
  username?: string;
  showCategory?: boolean;
  showRefresh?: boolean;
  showBrand?: boolean;
  showDateRange?: boolean;
  showRemainingCount?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  username = "User",
  showCategory = true,
  showRefresh = true,
  showBrand = true,
  showRemainingCount = true,
  showDateRange = true,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedCategories,
    setSelectedCategories,
    selectedBrands,
    setSelectedBrands,
    selectedDateRange,
    setSelectedDateRange,
    filters,
    loading,
    setLoading,
    refreshFilters,
    resetFilters
  } = useFilter();

  const [categorySearch, setCategorySearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");
  const [remainingProducts, setRemainingProducts] = useState<number | null>(null);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const categoryInputRef = useRef<HTMLInputElement>(null);
  const brandInputRef = useRef<HTMLInputElement>(null);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  // Filter categories based on search
  const filteredSearchCategories = useMemo(() => {
    if (!categorySearch || !Array.isArray(filters.source_category_id))
      return filters.source_category_id || [];

    return filters.source_category_id.filter((category: any) =>
      fuzzySearch(categorySearch, category.category_name)
    );
  }, [filters.source_category_id, categorySearch]);

  // Filter brands based on search
  const filteredSearchBrands = useMemo(() => {
    if (!brandSearch || !Array.isArray(filters.source_brand))
      return filters.source_brand || [];

    return filters.source_brand.filter((brand: any) =>
      fuzzySearch(brandSearch, brand.brand_name)
    );
  }, [filters.source_brand, brandSearch]);

  // // Handle category selection
  // const handleCategorySelect = async (keys: any) => {
  //   const selectedKeys = Array.from(keys) as string[];
  //   setSelectedCategories(selectedKeys);

  //   try {
  //     // Blur and clear category search input
  //     setCategorySearch("");
  //     setIsCategoryDropdownOpen(false);
  //     setLoading(true);

  //     setTimeout(() => {
  //       if (categoryInputRef.current) {
  //         categoryInputRef.current.blur();
  //       }
  //     }, 1000);

  //     // Only refresh filters if there are selections
  //     if (selectedKeys.length > 0 || selectedBrands.length > 0) {
  //       await refreshFilters(selectedKeys, selectedBrands);
  //     }

  //     // Update remaining count
  //     if (selectedKeys.length > 0) {
  //       const batchResponse = await getProductBatch({
  //         includes: {
  //           ...(selectedCategories.length > 0 && {
  //             source_category_id: selectedCategories
  //           }),
  //           ...(selectedBrands.length > 0 && {
  //             source_brand: selectedBrands
  //           })
  //         }
  //       });
  //       if (batchResponse.data.remaining_count !== undefined) {
  //         setRemainingProducts(batchResponse.data.remaining_count);
  //       }
  //     } else {
  //       setRemainingProducts(null);
  //     }
  //   } catch (error) {
  //     console.error("Error refreshing filters:", error);
  //     enqueueSnackbar("Failed to refresh filters", {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // // Handle brand selection
  // const handleBrandSelect = async (keys: any) => {
  //   const selectedKeys = Array.from(keys) as string[];
  //   setSelectedBrands(selectedKeys);

  //   try {
  //     // Blur and clear brand search input
  //     setBrandSearch("");
  //     setIsBrandDropdownOpen(false);
  //     setLoading(true);

  //     setTimeout(() => {
  //       if (brandInputRef.current) {
  //         brandInputRef.current.blur();
  //       }
  //     }, 1000);

  //     // Only refresh filters if there are selections
  //     if (selectedKeys.length > 0 || selectedCategories.length > 0) {
  //       await refreshFilters(selectedKeys, selectedCategories);
  //     }

  //     // Update remaining count
  //     if (selectedKeys.length > 0) {
  //       const batchResponse = await getProductBatch({
  //         includes: {
  //           ...(selectedCategories.length > 0 && {
  //             source_category_id: selectedCategories
  //           }),
  //           ...(selectedBrands.length > 0 && {
  //             source_brand: selectedBrands
  //           })
  //         }
  //       });
  //       if (batchResponse.data.remaining_count !== undefined) {
  //         setRemainingProducts(batchResponse.data.remaining_count);
  //       }
  //     } else {
  //       setRemainingProducts(null);
  //     }
  //   } catch (error) {
  //     console.error("Error refreshing filters:", error);
  //     enqueueSnackbar("Failed to refresh filters", {
  //       variant: "error",
  //       autoHideDuration: 3000,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (filters?.date_created?.data) {
      const minTimestamp = filters.date_created.data.min;
      const maxTimestamp = filters.date_created.data.max;

      const formatDate = (timestamp: string | null) => {
        if (!timestamp) return null;
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-CA');
      };

      setMinDate(minTimestamp ? new Date(formatDate(minTimestamp)!) : null);
      setMaxDate(maxTimestamp ? new Date(formatDate(maxTimestamp)!) : null);
    }
  }, [filters]);

  const handleRefresh = useCallback(async () => {
    if (selectedCategories.length === 0 && selectedBrands.length === 0 && selectedDateRange[0] === null && selectedDateRange[1] === null) {
      enqueueSnackbar("Please select at least one filter", {
        variant: "warning",
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      const response = await getProductBatch({
        includes: {
          ...(selectedCategories.length > 0 && {
            source_category_id: selectedCategories
          }),
          ...(selectedBrands.length > 0 && {
            source_brand: selectedBrands
          }),
          ...(selectedDateRange[0] !== null && selectedDateRange[1] !== null && {
            date_created: {
              start: selectedDateRange[0].toLocaleDateString("en-CA"),
              end: selectedDateRange[1].toLocaleDateString("en-CA"),
            }
          })
        },
        refresh: true,
      });

      if (response.data.remaining_count !== undefined) {
        setRemainingProducts(response.data.remaining_count);
      }

      if (location.pathname.includes("/dashboard/tag")) {
        navigate("/dashboard");
        setTimeout(() => navigate("/dashboard/tag"), 0);
      }

      enqueueSnackbar("Successfully refreshed product batch", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      console.error("Error refreshing batch:", error);
      enqueueSnackbar("Failed to refresh batch: " + (error as Error).message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [selectedCategories, selectedBrands, selectedDateRange, navigate, location.pathname, enqueueSnackbar]);

  // Handle reset
  const handleReset = async () => {
    try {
      setLoading(true);
      resetFilters();
      setCategorySearch("");
      setBrandSearch("");
      setIsCategoryDropdownOpen(false);
      setIsBrandDropdownOpen(false);
      await refreshFilters([], [], [null, null]);
    } catch (error) {
      console.error("Error resetting filters:", error);
      enqueueSnackbar("Failed to reset filters", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // Cleanup search states on unmount
  useEffect(() => {
    return () => {
      setCategorySearch("");
      setBrandSearch("");
    };
  }, []);

  useEffect(() => {

    const updateRemainingCount = (event: CustomEvent) => {
      if (event.detail?.remaining_count !== undefined) {
        setRemainingProducts(event.detail.remaining_count);
      }
    };

    window.addEventListener(
      "get_batch_response",
      updateRemainingCount as EventListener
    );
    return () => {
      window.removeEventListener(
        "get_batch_response",
        updateRemainingCount as EventListener
      );
    };
  }, []);

  useEffect(() => {
    const fetchRemainingCount = async () => {
      setLoading(true);
      try {
        if (selectedCategories.length > 0 || selectedBrands.length > 0 || (selectedDateRange[0] && selectedDateRange[1])) {
          const batchResponse = await getProductBatch({
            includes: {
              ...(selectedCategories.length > 0 && {
                source_category_id: selectedCategories
              }),
              ...(selectedBrands.length > 0 && {
                source_brand: selectedBrands
              }),
              ...(selectedDateRange[0] && selectedDateRange[1] && {
                date_created: {
                  start: selectedDateRange[0].toLocaleDateString("en-CA"),
                  end: selectedDateRange[1].toLocaleDateString("en-CA"),
                }
              })
            }
          });
          if (batchResponse.data.remaining_count !== undefined) {
            setRemainingProducts(batchResponse.data.remaining_count);
          }
        } else {
          setRemainingProducts(null);
        }
      } catch (error) {
        console.error("Error fetching remaining count:", error);
        enqueueSnackbar(
          "Failed to load remaining count: " + (error as Error).message,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchRemainingCount();
  }, [selectedCategories, selectedBrands, selectedDateRange, location.pathname]);


  useEffect(() => {
    const refreshTaggingFilters = async () => {
      if (selectedCategories.length > 0 || selectedBrands.length > 0 || selectedDateRange[0] !== null || selectedDateRange[1] !== null) {
        await refreshFilters(selectedCategories, selectedBrands, selectedDateRange);
      }
    }
    refreshTaggingFilters();
  }, [selectedCategories, selectedBrands, selectedDateRange]);


  return (
    <div
      style={{
        width: "100%",
        height: "56px",
        backgroundColor: "white",
        borderBottom: "1px solid #eaeaea",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Container
        display="flex"
        alignItems="center"
        justify="space-between"
        css={{
          height: "100%",
          padding: "0 16px",
          maxWidth: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Text
            b
            size={16}
            css={{
              color: "$text",
              marginRight: "16px",
            }}
          >
            Product Matching Dashboard
          </Text>
          {showCategory && (
             <div style={{ width: '180px', position: 'relative' }}>
             <Popover placement="bottom-left">
               <Popover.Trigger>
                 <Button
                   flat
                   css={{
                     width: '100%',
                     justifyContent: 'space-between',
                     backgroundColor: '#CEE4FE',
                     height: '38px',
                     marginTop: '2px'
                   }}
                 >
                   {selectedCategories.length > 0
                     ? `${selectedCategories.length} categories selected`
                     : "Categories"}
                 </Button>
               </Popover.Trigger>
               <Popover.Content css={{ width: '180px', maxHeight: '400px', overflowY: 'scroll' }}>
                 <div style={{ padding: '8px' }}>
                   <Input
                     type="text"
                     ref={categoryInputRef}
                     value={categorySearch}
                     onChange={(e) => setCategorySearch(e.target.value)}
                     placeholder="Search categories..."
                     css={{ marginBottom: '8px' }}
                     clearable
                   />
                   <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                     {filters?.source_category_id
                       ?.filter((category: any) =>
                         !categorySearch ||
                         fuzzySearch(categorySearch, category.category_name)
                       )
                       .map((category: any) => (
                         <Button
                           key={category.category_id}
                           flat
                           size="sm"
                           css={{
                             justifyContent: 'flex-start',
                             backgroundColor: selectedCategories.includes(String(category.category_id))
                               ? '$primary'
                               : 'transparent',
                             color: selectedCategories.includes(String(category.category_id))
                               ? 'white'
                               : '$text',
                             '&:hover': {
                               backgroundColor: selectedCategories.includes(String(category.category_id))
                                 ? '$primary'
                                 : '$accents1'
                             },
                           }}
                           onClick={() => {
                             const newSelected = [...selectedCategories];
                             if (newSelected.includes(String(category.category_id))) {
                               newSelected.splice(newSelected.indexOf(String(category.category_id)), 1);
                             } else {
                               newSelected.push(String(category.category_id));
                             }
                             setSelectedCategories(newSelected);

                             setTimeout(() => {
                               if (categoryInputRef.current) {
                                 categoryInputRef.current.blur();
                               }
                             }, 500);
                             
                             if (document.activeElement instanceof HTMLElement) {
                               document.activeElement.blur();
                             }
                           }}
                         >
                           {formatCategoryName(category.category_name) || 'Unknown Category'}
                         </Button>
                       ))}
                   </div>
                   {filters?.source_category_id?.length === 0 && (
                     <Text css={{ color: '$accents6', textAlign: 'center', padding: '8px' }}>
                       No categories found
                     </Text>
                   )}
                 </div>
               </Popover.Content>
             </Popover>
          </div>
          )}
          {showBrand && (
             <div style={{ width: '180px', position: 'relative'}}>
              <Popover placement="bottom-left">
                <Popover.Trigger>
                  <Button
                    flat
                    css={{
                      width: '100%',
                      justifyContent: 'space-between',
                      backgroundColor: '#CEE4FE',
                      height: '38px',
                      marginTop: '2px'
                    }}
                  >
                    {selectedBrands.length > 0
                      ? `${selectedBrands.length} brands selected`
                      : "Brands"}
                  </Button>
                </Popover.Trigger>
                <Popover.Content css={{ width: '180px', maxHeight: '400px', overflowY: 'scroll' }}>
                  <div style={{ padding: '8px' }}>
                    <Input
                      type="text"
                      ref={brandInputRef}
                      value={brandSearch}
                      onChange={(e) => setBrandSearch(e.target.value)}
                      placeholder="Search brands..."
                      css={{ marginBottom: '8px' }}
                      clearable
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      {filters?.source_brand
                        ?.filter((brand: any) =>
                          !brandSearch ||
                          fuzzySearch(brandSearch, brand.brand_name)
                        )
                        .map((brand: any) => (
                          <Button
                            key={brand.brand_id}
                            flat
                            size="sm"
                            css={{
                              justifyContent: 'flex-start',
                              backgroundColor: selectedBrands.includes(String(brand.brand_id))
                                ? '$primary'
                                : 'transparent',
                              color: selectedBrands.includes(String(brand.brand_id))
                                ? 'white'
                                : '$text',
                              '&:hover': {
                                backgroundColor: selectedBrands.includes(String(brand.brand_id))
                                  ? '$primary'
                                  : '$accents1'
                              },
                            }}
                            onClick={() => {
                              const newSelected = [...selectedBrands];
                              if (newSelected.includes(String(brand.brand_id))) {
                                newSelected.splice(newSelected.indexOf(String(brand.brand_id)), 1);
                              } else {
                                newSelected.push(String(brand.brand_id));
                              }
                              setSelectedBrands(newSelected);

                              setTimeout(() => {
                                if (brandInputRef.current) {
                                  brandInputRef.current.blur();
                                }
                              }, 500);

                              if (document.activeElement instanceof HTMLElement) {
                                document.activeElement.blur();
                              }
                            }}
                          >
                            {brand.brand_name || 'Unknown Brand'}
                          </Button>
                        ))}
                    </div>
                    {filters?.source_brand?.length === 0 && (
                      <Text css={{ color: '$accents6', textAlign: 'center', padding: '8px' }}>
                        No brands found
                      </Text>
                    )}
                  </div>
                </Popover.Content>
              </Popover>
           </div>
          )}
          {showDateRange && (
          <div style={{ width: '180px', position: 'relative'}}>
            <Popover
              isOpen={isDatePopoverOpen}
              onOpenChange={setIsDatePopoverOpen}
              placement="bottom"
            >
              <Popover.Trigger>
                <Button flat
                  size="sm"
                  css={{
                    width: '100%',
                    justifyContent: 'space-between',
                    backgroundColor: '#CEE4FE',
                    height: '38px',
                    marginTop: '2px'
                  }}
                >
                  {selectedDateRange[0] && selectedDateRange[1]
                    ? `${selectedDateRange[0].toLocaleDateString()} - ${selectedDateRange[1].toLocaleDateString()}`
                    : "Date Range"}
                </Button>
              </Popover.Trigger>
              <Popover.Content>
                <div style={{ padding: "1rem" }}>
                  <DatePicker
                    type="range"
                    minDate={minDate || undefined}
                    maxDate={maxDate || undefined}
                    value={selectedDateRange}
                    onChange={(dates) => {
                      if (Array.isArray(dates)) {
                        const [start, end] = dates;
                        setSelectedDateRange([start, end]);
                        if (start && end) {
                          setIsDatePopoverOpen(false);
                          refreshFilters(selectedCategories, selectedBrands, [start, end]);
                        }
                      }

                      if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur();
                      }
                    }}
                  />
                  {selectedDateRange[0] && selectedDateRange[1] && (
                    <Button
                      size="sm"
                      color="error"
                      light
                      css={{ marginTop: "8px" }}
                      onClick={() => {
                        setSelectedDateRange([null, null]);
                        setIsDatePopoverOpen(false);
                        refreshFilters(selectedCategories, selectedBrands, [null, null]);
                      }}
                    >
                      Clear Dates
                    </Button>
                  )}
                </div>
              </Popover.Content>
            </Popover>
            </div>
          )}
          
          {(showCategory || showBrand || showDateRange) && (
            <Button
              auto
              light
              size="sm"
              onClick={handleReset}
              disabled={selectedCategories.length === 0 && selectedBrands.length === 0 && (selectedDateRange[0] == null || selectedDateRange[1] == null)}
              css={{
                fontSize: '13px',
                height: '32px',
                minWidth: '80px',
                backgroundColor: '#FFE7E7',
                color: '#FF4D4F',
                '&:hover': {
                  backgroundColor: '#FFF1F0',
                },
                '&:disabled': {
                  backgroundColor: '#FFF1F0',
                  color: '#FFADAD',
                }
              }}
            >
              Reset Filters
            </Button>
          )}
          {showRemainingCount && (
            <>
              {loading ? (
                <Loading size="sm" />
              ) : remainingProducts === null ? (
                <Text css={{ color: "$accents6" }}>No products remaining</Text>
              ) : (
                <Text css={{ color: "$gray700" }}>
                  <span style={{ fontWeight: "bold" }}>{remainingProducts}</span>{" "}
                  remaining
                </Text>
              )}
            </>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Text css={{ color: "$text", fontSize: '13px' }}>
            Welcome, {user?.name?.split(" ")?.[0] || user?.email || username}
          </Text>
          {showRefresh && (
            <Button
              auto
              flat
              size="sm"
              onClick={handleRefresh}
              disabled={selectedCategories.length === 0 && selectedBrands.length === 0 && (selectedDateRange[0] == null || selectedDateRange[1] == null )}
              css={{
                fontSize: '13px',
                height: '32px',
                minWidth: '100px'
              }}
            >
              Refresh Batch
            </Button>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
