import axios, { AxiosError } from "axios";
import axiosWithHeader from "./interceptors";

interface Product {
  product_id: string;
  category_id: string;
  channel_id: string;
  source_image_url: string;
  matches: Match[];
}

interface Match {
  product_id: string;
  category_id: string;
  channel_id: string;
  target_image_url: string;
  score: number;
}

interface GetBatchParams {
  includes?: {
    source_category_id?: string[];
    source_brand?: string[];
  };
  refresh?: boolean;
}

interface BatchUploadResponse {
  success_count: number;
  failed_count: number;
}

interface UploadSummary {
  total_documents: number;
  success_count: number;
  failed_count: number;
  time_taken: number;
}

export const getCategories = async () => {
  try {
    const response = await axiosWithHeader.get("/api/tagging/categories");
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch categories: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch categories");
  }
};

export const getBrands = async () => {
  try {
    const response = await axiosWithHeader.get("/api/tagging/brands");
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(`Failed to fetch brands: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch brands");
  }
};

export const getTaggingFilters = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post("/api/tagging/get_tagg_filters", {
      includes: {
        ...(params?.selectedCategories?.length > 0 && {
          source_category_id: params.selectedCategories
        }),
        ...(params?.selectedBrands?.length > 0 && {
          source_brand: params.selectedBrands
        }),
        ...(params?.selectedDateRange?.[0] && params?.selectedDateRange?.[1] && {
          date_created: {
            start: params.selectedDateRange[0].toLocaleDateString("en-CA"),
            end: params.selectedDateRange[1].toLocaleDateString("en-CA"),
          }
        })
      }
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch tagging filters");
  }
};

export const getProductBatch = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/tagging/get_batch",
      params
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch product batch: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch product batch");
  }
};

export const tagProduct = async (body = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/tagging/tag_product",
      body
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to tag product: ${axiosError.message}`);
    }
    throw new Error("Failed to tag product");
  }
};

export const completeCurrentBatch = async () => {
  try {
    await axiosWithHeader.post("/api/tagging/complete_batch");
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to remove previous batch: ${axiosError.message}`);
    }
    throw new Error("Failed to remove previous batch");
  }
};

export const getReviewBatch = async (params: any) => {
  try {
    const response = await axiosWithHeader.post("/api/review/get_batch", params);
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch review batch: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch review batch");
  }
};

export const updateMatch = async (body = {}) => {
  try {
    const response = await axiosWithHeader.post(
      "/api/review/review_product",
      body
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(`Failed to update match: ${axiosError.message}`);
    }
    throw new Error("Failed to update match");
  }
};

export const bulkReview = async (body = {}) => {
  try {
    await axiosWithHeader.post("/api/review/bulk_review", body);
  } catch (error) {
    throw new Error("Failed to approve all");
  }
};

export const getReviewFilters = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post("/api/review/get_review_filters", params || {});
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        throw new Error("Unauthorized: Please log in again");
      }
      throw new Error(`Failed to fetch review filters: ${axiosError.message}`);
    }
    throw new Error("Failed to fetch review filters");
  }
};

export const get_user_analytics = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/user_analytics', params || {});
    return response;
  } catch (error: any) {
    if (error.response?.status === 401) {
      throw new Error("Session expired, please refresh");
    }
    throw new Error(error.response?.data?.message || "Failed to load analytics data");
  }
};

export const get_category_analytics = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/category_analytics', params || {});
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load category overview");
  }
};

export const get_overall_analytics = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/overall_analytics', params || {});
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load overall analytics");
  }
};

export const get_brand_analytics = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/brand_analytics', params || {});
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load brand analytics");
  }
};

export const get_analytics_tagger_filters = async (params?: any) => {
  try {
    const response = await axiosWithHeader.post('/api/analytics/tagger_overview_filters', params || {});
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "Failed to load analytics filters");
  }
};

export const uploadJsonBatch = async (
  batch: any[], 
  signal?: AbortSignal,
  onUploadProgress?: (progressEvent: any) => void
): Promise<BatchUploadResponse> => {
  try {
    const response = await axiosWithHeader.post('/api/admin/upload_matches', batch, {
      signal,
      onUploadProgress
    });
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error('Upload cancelled');
    }
    if (axios.isAxiosError(error)) {
      throw new Error(`Failed to upload batch: ${error.message}`);
    }
    throw new Error('Failed to upload batch');
  }
};

export const sendUploadSummary = async (summary: UploadSummary): Promise<void> => {
  try {
    await axiosWithHeader.post('/api/admin/upload_summary', summary);
  } catch (error) {
    console.error('Failed to send upload summary:', error);
    throw new Error('Failed to send upload summary');
  }
};

// export const fetchProduct = async (
//   page: number,
//   userEmail: string
// ): Promise<{ product: Product; totalPages: number }> => {
//   try {
//     const response = await axiosWithHeader.get<{
//       product: Product;
//       totalPages: number;
//     }>(`/product`, {
//       params: { page, userEmail },
//     });
//     return response.data;
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to fetch product: ${axiosError.message}`);
//     }
//     throw new Error("Failed to fetch product");
//   }
// };

// export const submitTag = async (
//   productId: string,
//   matchId: string,
//   userEmail: string
// ): Promise<void> => {
//   try {
//     await axiosWithHeader.post(
//       `/tagging/tag`,
//       {
//         productId,
//         matchId,
//         userEmail,
//       }
//     );
//   } catch (error) {
//     if (axios.isAxiosError(error)) {
//       const axiosError = error as AxiosError;
//       throw new Error(`Failed to submit tag: ${axiosError.message}`);
//     }
//     throw new Error("Failed to submit tag");
//   }
// };

