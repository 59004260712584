import React, { useState, useEffect, useCallback, memo, useRef } from "react";
import {
  Container,
  Button,
  Text,
  Loading,
  Modal as NextModal,
} from "@nextui-org/react";
import ProductCard from "./ProductCard";
import { closeSnackbar, useSnackbar } from "notistack";
import { useFilter } from "../context/FilterContext";

import {
  BATCH_EXPIRED_API_KEY,
  FAILED_API_MSG,
  NUMBER_PRONUNCIATION,
  PRODUCT_DASH_CONST,
  TAGGER_NEXT_BUTTON_TOOLTIP_TEXT,
} from "../constants/constant";
import {
  getProductBatch,
  completeCurrentBatch,
  tagProduct,
} from "../api/dashboard";
import ProgressBarLoading from "./progressBarLoading";

import "../styles/productTagger.css";
import "./ProductTagger.css";

interface Product {
  _id: string;
  source_product_id: string;
  source_pdp_url: string;
  source_feature_image_s3: string;
  source_mrp: number;
  source_selling_price: number;
  source_product_name: string;
  source_brand: string;
  matches: Match[];
}

interface Match {
  match_product_id: string;
  match_score: number;
  match_pdp_url: string;
  match_feature_image_s3: string;
  match_mrp: number;
  match_selling_price: number;
  match_product_name: string;
  match_brand: string;
}

interface User {
  email: string;
  name?: string;
  [key: string]: any;
}

interface ProductTaggerProps {
  user: User | null;
}

const ProductTagger: React.FC<ProductTaggerProps> = memo(({ user }) => {
  //snackbar
  const { enqueueSnackbar } = useSnackbar();
  const { selectedCategories, selectedBrands, selectedDateRange } = useFilter();
  //states
  const [products, setProducts] = useState<Product[]>([]);
  const [currentProductNumber, setCurrentProductNumber] = useState<number>(0);
  const [selectedMatch, setSelectedMatch] = useState<number | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [productNumber, setProductNumber] = useState<number>(0);
  const batchRef = useRef<boolean | null>();

  const hasActiveFilter = useCallback(() => {
    return !!(selectedCategories.length > 0 || selectedBrands.length > 0 || (selectedDateRange[0] && selectedDateRange[1]));
  }, [selectedCategories, selectedBrands, selectedDateRange]);

  const loadProduct = useCallback(
    async (refresh?: boolean) => {
      setLoading(true);
      setError(null);

      if (!hasActiveFilter()) {
        setLoading(false);
        setProducts([]);
        setCurrentProductNumber(0);
        setProductNumber(0);
        enqueueSnackbar("Please select a category or brand to start tagging", {
          variant: "warning",
          autoHideDuration: 3000,
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
        return;
      }

      try {
        const response = await getProductBatch({
          includes: {
            ...(selectedCategories.length > 0 && {
              source_category_id: selectedCategories
            }),
            ...(selectedBrands.length > 0 && {
              source_brand: selectedBrands
            }),
            ...(selectedDateRange[0] && selectedDateRange[1] && {
              date_created: {
                start: selectedDateRange[0].toLocaleDateString("en-CA"),
                end: selectedDateRange[1].toLocaleDateString("en-CA"),
              }
            })
          },
          refresh: refresh || undefined,
        });

        // Dispatch event with remaining count
        if (response.data?.remaining_count !== undefined) {
          window.dispatchEvent(
            new CustomEvent("get_batch_response", {
              detail: { remaining_count: response.data.remaining_count },
            })
          );
        }

        // Extract products from the response
        const productsData = response.data?.products || response.data;
        if (productsData) {
          setProducts(
            Array.isArray(productsData) ? productsData : [productsData]
          );
          setCurrentProductNumber(response.data?.itr || 0);
          setSelectedMatch(null);
          setProductNumber(response.data?.itr || 0);
        } else {
          setError("No products available reset the filters");
        }
      } catch (error) {
        console.error("Error loading products:", error);
        setError((error as Error).message);
        setProducts([]);
        setCurrentProductNumber(0);
        setProductNumber(0);
        enqueueSnackbar(
          "Failed to load products: " + (error as Error).message,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [selectedCategories, selectedBrands, selectedDateRange, hasActiveFilter, enqueueSnackbar]
  );

  useEffect(() => {
    if (hasActiveFilter()) {
      loadProduct(true);
    }
  }, [hasActiveFilter, loadProduct]);

  const handleNextClick = useCallback(() => {
    if (!hasActiveFilter()) {
      showSnackbar("Please select a category or brand first", "warning");
      return;
    }
    setSelectedMatch(null);
    setShowConfirmation(true);
  }, [hasActiveFilter, enqueueSnackbar]);

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (!hasActiveFilter()) {
        return;
      }
      
      if (loading || loader) {
        return;
      }
      
      // Handle number keys 1-9 for selecting matches
      const key = parseInt(event.key);
      if (!isNaN(key) && key >= 1 && key <= 9) {
        const matchIndex = key - 1;
        if (matchIndex == selectedMatch) {
          setSelectedMatch(null);
        } else if (
          products[currentProductNumber]?.matches &&
          matchIndex < products[currentProductNumber].matches.length
        ) {
          setSelectedMatch(matchIndex);
        }
      } else if (!isNaN(key) && key === 0) {
        handleNoMatch();
      }
      // Handle Enter key for confirming selection
      if (
        event.key === PRODUCT_DASH_CONST.KEYS.ENTER &&
        selectedMatch !== null
      ) {
        if (selectedMatch === -1) {
          handleTagProduct(PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT);
        } else {
          handleTagProduct(PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT);
        }
      }

      if (
        event.key === PRODUCT_DASH_CONST.KEYS.ENTER &&
        selectedMatch === null
      ) {
        if (showConfirmation) {
          if (products.length === currentProductNumber + 1) {
            await completeCurrentBatch();
            await loadProduct(true);
          } else {
            setCurrentProductNumber(currentProductNumber + 1);
            setProductNumber((prev) => prev + 1);
          }
          setShowConfirmation(false);
        }
      }
    };

    window.addEventListener(
      PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
      handleKeyPress as unknown as EventListener
    );

    return () => {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleKeyPress as unknown as EventListener
      );
    };
  }, [selectedMatch, products, currentProductNumber, showConfirmation, hasActiveFilter, loading, loader]);

  useEffect(() => {
    const handleArrowKeyPress = (event: KeyboardEvent) => {
      if (event.key === PRODUCT_DASH_CONST.KEYS.ARROW_RIGHT) {
        handleNextClick();
      }
    };

    window.addEventListener(
      PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
      handleArrowKeyPress as EventListener
    );

    return () => {
      window.removeEventListener(
        PRODUCT_DASH_CONST.EVENT_TYPE.KEYDOWN,
        handleArrowKeyPress as EventListener
      );
    };
  }, [handleNextClick]);

  const handleMatchSelect = (matchId: number) => {
    if (!hasActiveFilter()) {
      showSnackbar("Please select a category or brand first", "warning");
      return;
    }
    setSelectedMatch(matchId);
    setShowConfirmation(true);
  };

  const closeClickHandler = () => {
    setShowConfirmation(false);
    setSelectedMatch(null);
  };

  const showSnackbar = useCallback(
    (
      message: string,
      variant: "success" | "error" | "warning" | "info" | "default"
    ) => {
      enqueueSnackbar(message, {
        variant,
        autoHideDuration: 2000,
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    },
    [enqueueSnackbar]
  );

  const handleNoMatch = function () {
    if (!hasActiveFilter()) {
      showSnackbar("Please select a category or brand first", "warning");
      return;
    }
    setSelectedMatch(-1);
    setShowConfirmation(true);
  };

  const handleTagProduct = useCallback(
    async (status: string) => {
      if (!hasActiveFilter()) {
        showSnackbar("Please select a category or brand first", "warning");
        return;
      }

      if (products) {
        setLoader(true);
        setShowConfirmation(false);
        const inprogress = enqueueSnackbar(
          PRODUCT_DASH_CONST.SNACKBAR.TAG_INPROGRES,
          {
            variant: "info" as const,
            persist: true,
          }
        );

        try {
          const tagPayload = {
            source_product_id:
              products[currentProductNumber]?.source_product_id,
            match_product_id:
              status === PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT
                ? products[currentProductNumber]?.matches[selectedMatch || 0]
                    ?.match_product_id
                : null,
            status: status,
          };
          const tagProductRes: any = await tagProduct(tagPayload).then(
            (data) => {
              if (status !== PRODUCT_DASH_CONST.REVIEW_STATUS.SKIPPED) {
                closeSnackbar(inprogress);
                showSnackbar(PRODUCT_DASH_CONST.SNACKBAR.TAGGED, "success");
              } else {
                closeSnackbar(inprogress);
                showSnackbar(PRODUCT_DASH_CONST.SNACKBAR.SKIP, "info");
              }
              return data;
            },
            (error) => {
              if (error?.response?.data?.error === BATCH_EXPIRED_API_KEY) {
                setShowReloadModal(true);
              } else {
                closeSnackbar(inprogress);
                showSnackbar(
                  error?.response?.data?.error || FAILED_API_MSG,
                  "error"
                );
              }
              throw error;
            }
          );

          if (products.length === currentProductNumber + 1) {
            await completeCurrentBatch();
            await loadProduct(true);
          } else {
            setCurrentProductNumber(currentProductNumber + 1);
            setSelectedMatch(null);
            setProductNumber((prev) => prev + 1);
          }
        } catch (error) {
          console.error("Error tagging product:", error);
        } finally {
          setLoader(false);
        }
      }
    },
    [
      products,
      currentProductNumber,
      selectedMatch,
      selectedCategories,
      selectedBrands,
      enqueueSnackbar,
      showSnackbar,
      closeSnackbar,
      hasActiveFilter,
    ]
  );

  const formatScore = (score: number): string => {
    return (score * 100).toFixed(2) + "%";
  };

  const getMatchOrdinal = (index: number): string => {
    if (index > 10) {
      const lastDigit = index % 10;
      if (lastDigit > 3) {
        return `${index}th`;
      }
      return `${index}${NUMBER_PRONUNCIATION[lastDigit]}`;
    }
    return `${index}${NUMBER_PRONUNCIATION[index]}`;
  };

  if (!hasActiveFilter()) {
    return (
      <Container css={{ textAlign: "center", marginTop: "2rem" }}>
        <Text h3>No Filter Selected</Text>
        <Text css={{ color: "$accents6", marginTop: "1rem" }}>
          Please select at least one filter.
        </Text>
      </Container>
    );
  }

  if (loading) {
    return <ProgressBarLoading message={PRODUCT_DASH_CONST.LOADING_PRODUCT} />;
  }

  if (error) {
    return (
      <Container>
        <Text color="error">{error}</Text>
        <Button onClick={() => loadProduct(true)}>
          {PRODUCT_DASH_CONST.RETRY}
        </Button>
      </Container>
    );
  }

  if (!products?.length) {
    return <Text>{PRODUCT_DASH_CONST.NO_PRODUCT}</Text>;
  }

  return (
    <>
      <div className="product-matches-container">
        <div className="source-product">
          <Text h4>Source Product</Text>
          <ProductCard
            imgURL={products[currentProductNumber]?.source_feature_image_s3}
            name={products[currentProductNumber]?.source_product_name}
            mrp={products[currentProductNumber]?.source_mrp}
            pdpURL={products[currentProductNumber]?.source_pdp_url}
            brand={products[currentProductNumber]?.source_brand}
          />
          <div
            className="control-buttons"
            style={{
              marginTop: "16px",
              display: "flex",
              gap: "8px",
              flexDirection: "column",
            }}
          >
            <Button
              color="primary"
              onClick={handleNextClick}
              title={TAGGER_NEXT_BUTTON_TOOLTIP_TEXT}
              css={{ flex: 1 }}
              disabled={!hasActiveFilter()}
            >
              {PRODUCT_DASH_CONST.NEXT} (&rarr;)
            </Button>
            <Button
              color="error"
              onClick={() => {
                handleNoMatch();
              }}
              css={{ flex: 1 }}
              disabled={!hasActiveFilter()}
            >
              {PRODUCT_DASH_CONST.NOT_MATCH}
            </Button>
          </div>
        </div>

        <div className="matches-container">
          {products &&
            products[currentProductNumber]?.matches.map((match, index) => (
              <div key={match.match_product_id} className="match-item">
                <ProductCard
                  imgURL={match.match_feature_image_s3}
                  name={match.match_product_name}
                  mrp={match.match_mrp}
                  pdpURL={match.match_pdp_url}
                  brand={match.match_brand}
                  serialNumber={index + 1}
                  highlighted={selectedMatch === index}
                  onProductCardExtClick={() => handleMatchSelect(index)}
                />
              </div>
            ))}
        </div>
        {loader && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999,
            }}
          >
            <Loading size="xl" />
          </div>
        )}
      </div>

      {showConfirmation && (
        <NextModal
          open={showConfirmation}
          onClose={closeClickHandler}
          aria-labelledby="confirmation-modal"
          className="confirmation-modal"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Text h4>Confirmation</Text>
            </div>
            <div className="modal-body">
              <Text>
                {selectedMatch === -1
                  ? "Are you sure this product has no matches?"
                  : ""}
                {selectedMatch === null
                  ? "Are you sure you want to skip this product?"
                  : ""}
                {selectedMatch !== -1 && selectedMatch !== null
                  ? `Are you sure you want to tag this product as a ${getMatchOrdinal(
                      selectedMatch + 1
                    )} match?`
                  : ""}
              </Text>
            </div>
            <div className="modal-footer">
              <Button
                auto
                flat
                css={{
                  backgroundColor: "#f5f5f5",
                  color: "grey",
                }}
                onPress={closeClickHandler}
              >
                Cancel
              </Button>
              <Button
                auto
                color="primary"
                onPress={() => {
                  if (selectedMatch === -1) {
                    handleTagProduct(PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT);
                  } else if (selectedMatch === null) {
                    setCurrentProductNumber(currentProductNumber + 1);
                    setSelectedMatch(null);
                    setProductNumber((prev) => prev + 1);
                    setShowConfirmation(false);
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </NextModal>
      )}
    </>
  );
});

export default ProductTagger;
