export const formatPrice = (price: number): string => {
  return price.toLocaleString('en-IN');
};

export const formatCategoryName = (name: string) => {
  return name.toUpperCase();
};

export const fuzzySearch = (searchTerm: string, text: string) => {
  const search = searchTerm.toLowerCase();
  const target = text.toLowerCase();
  let searchIndex = 0;
  
  for (let i = 0; i < target.length && searchIndex < search.length; i++) {
    if (target[i] === search[searchIndex]) {
      searchIndex++;
    }
  }
  
  return searchIndex === search.length;
};
