import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Container,
  Button,
  Text,
  Loading,
  Dropdown,
  Popover,
  Modal as NextModal,
  Input,
} from "@nextui-org/react";
import ProductCard from "./ProductCard";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mantine/dates";
import "@mantine/core/styles.css";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAuth } from "../context/AuthContext";
import { Navigate } from 'react-router-dom';

import {
  BATCH_EXPIRED_API_KEY,
  FAILED_API_MSG,
  PRODUCT_DASH_CONST,
} from "../constants/constant";

import {
  completeCurrentBatch,
  getReviewBatch,
  updateMatch,
  bulkReview,
  getReviewFilters,
} from "../api/dashboard";

import "../styles/productTagger.css";
import "../styles/reviewDashboard.css";
import { formatCategoryName, fuzzySearch } from "../utils/helpers";

let searchNext = "";

interface Brand {
  brand_name: string;
}

interface FilterData {
  source_category_id: {
    data: Array<{
      category_id: string;
      category_name: string;
      count: number;
    }>;
  };
  source_brand: {
    data: Array<{
      brand_id: string;
      brand_name: string;
      count: number;
    }>;
  };
  taggers: {
    data: Array<{
      user_id: string;
      count: number;
    }>;
  };
  date_ranges: {
    min: string | null;
    max: string | null;
  };
  tag_status: {
    data: Array<{
      tag_status: string;
      count: number;
    }>;
  };
}

const ReviewDashboard: React.FC = memo(() => {
  const [products, setProducts] = useState<any>([]);
  const [currentProductNumber, setCurrentProductNumber] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<any>({})
  const [error, setError] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [reviewerSelectedMatches, setReviewerSelectedMatches] = useState<
    number[]
  >([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<number>(-1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showBulkConfimation, setShowBulkConfirmation] = useState(false);
  const [bulkAction, setBulkAction] = useState<string | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const { user } = useAuth();
  const isReviewer = user?.role?.includes("reviewer") || user?.role?.includes("admin");
  const [categorySearch, setCategorySearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [selectedMatchStatus, setSelectedMatchStatus] = useState<string | null>(null);

  useEffect(() => {
    if (products.length && !reviewerSelectedMatches.length) {
      setReviewerSelectedMatches((prev) => {
        const newMatches = Array(products.length).fill(-1);
        return newMatches;
      });
    }
  }, [products]);

  useEffect(() => {
    if (filters?.date_ranges) {
      const minTimestamp = filters?.date_ranges?.data?.min;
      const maxTimestamp = filters?.date_ranges?.data?.max;

      const formatDate = (timestamp: string | null) => {
        if (!timestamp) return null;
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-CA');
      };
      const formattedMinDate = formatDate(minTimestamp);
      const formattedMaxDate = formatDate(maxTimestamp);
      setMinDate(formattedMinDate ? new Date(formattedMinDate) : null);
      setMaxDate(formattedMaxDate ? new Date(formattedMaxDate) : null);
    }
  }, [filters]);

  const handleApproveAll = async () => {
    try {
      // if ((selectedDateRange[0] && !selectedDateRange[1]) || (!selectedDateRange[0] && selectedDateRange[1])) {
      //   enqueueSnackbar("Please select both start and end dates", {
      //     variant: "warning",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     autoHideDuration: 2000,
      //   });
      //   return;
      // }

      await bulkReview({
        action: "approve",
        includes: {
          ...(selectedCategories?.length && { source_category_id: selectedCategories }),
          ...(selectedBrands?.length && { source_brand: selectedBrands }),
          ...(selectedUser && { tagged_by: selectedUser }),
          ...(selectedDateRange[0] && selectedDateRange[1] && {
            tagged_timestamp: {
              start: selectedDateRange[0].toLocaleDateString("en-CA"),
              end: selectedDateRange[1].toLocaleDateString("en-CA"),
            },
          }),
          ...(selectedMatchStatus && { tag_status: selectedMatchStatus }),
        },
      }).then(async () => {
        loadProduct(true);
        enqueueSnackbar("Products approved successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to approve all", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  const handleRevertTagging = async () => {
    try {
      // if ((selectedDateRange[0] && !selectedDateRange[1]) || (!selectedDateRange[0] && selectedDateRange[1])) {
      //   enqueueSnackbar("Please select both start and end dates", {
      //     variant: "warning",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     autoHideDuration: 2000,
      //   });
      //   return;
      // }

      await bulkReview({
        action: "reject",
        includes: {
          ...(selectedCategories?.length && { source_category_id: selectedCategories }),
          ...(selectedBrands?.length && { source_brand: selectedBrands }),
          ...(selectedUser && { tagged_by: selectedUser }),
          ...(selectedDateRange[0] && selectedDateRange[1] && {
            tagged_timestamp: {
              start: selectedDateRange[0].toLocaleDateString("en-CA"),
              end: selectedDateRange[1].toLocaleDateString("en-CA"),
            },
          }),
          ...(selectedMatchStatus && { tag_status: selectedMatchStatus }),
        },
      }).then(() => {
        loadProduct(true);
        enqueueSnackbar("Products reverted successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to revert tagging", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (showConfirmation && event.key === "Escape") {
        setShowConfirmation(false);
        return;
      }

      if (showConfirmation) return; // Don't handle other keys when confirmation is shown

      if (event.key === "Enter" && !handleDisable()) {
        handleUpdateMatch();
        return;
      }

      if (
        +event.key > 0 &&
        +event.key <= products[currentProductNumber]?.matches.length
      ) {
        const newMatch = +event.key - 1;
        setSelectedMatch(newMatch);
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = newMatch;
          return newMatches;
        });
      }
      if (event.key === "0") {
        setSelectedMatch(-1);
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = -1;
          return newMatches;
        });
        handleNoMatch();
      }
      switch (event.key) {
        case "ArrowUp":
          event.preventDefault();
          if (
            reviewerSelectedMatches[currentProductNumber] !== -1 &&
            products[currentProductNumber]?.tagged_product_id !==
            products[currentProductNumber]?.matches[
              reviewerSelectedMatches[currentProductNumber]
            ]?.match_product_id
          ) {
            setShowConfirmation(true);
          } else {
            const newIndex = Math.max(0, currentProductNumber - 1);
            setCurrentProductNumber(newIndex);
            setSelectedMatch(-1);

            // Find the list container and calculate scroll position
            const listContainer = document.querySelector(".product-list");
            if (listContainer) {
              const itemHeight = 80; // height of each item
              const containerHeight = listContainer.clientHeight;
              const targetScrollTop = newIndex * itemHeight;
              const currentScrollTop = listContainer.scrollTop;

              // If item is out of view, scroll to it
              if (
                targetScrollTop < currentScrollTop ||
                targetScrollTop >
                currentScrollTop + containerHeight - itemHeight
              ) {
                listContainer.scrollTo({
                  top: targetScrollTop - containerHeight / 2 + itemHeight / 2,
                  behavior: "smooth",
                });
              }
            }
          }
          break;
        case "ArrowDown":
          event.preventDefault();
          if (
            reviewerSelectedMatches[currentProductNumber] !== -1 &&
            products[currentProductNumber]?.tagged_product_id !==
            products[currentProductNumber]?.matches[
              reviewerSelectedMatches[currentProductNumber]
            ]?.match_product_id
          ) {
            setShowConfirmation(true);
          } else {
            const newIndex = Math.min(
              products.length - 1,
              currentProductNumber + 1
            );
            setCurrentProductNumber(newIndex);
            setSelectedMatch(-1);

            // Find the list container and calculate scroll position
            const listContainer = document.querySelector(".product-list");
            if (listContainer) {
              const itemHeight = 80; // height of each item
              const containerHeight = listContainer.clientHeight;
              const targetScrollTop = newIndex * itemHeight;
              const currentScrollTop = listContainer.scrollTop;

              // If item is out of view, scroll to it
              if (
                targetScrollTop < currentScrollTop ||
                targetScrollTop >
                currentScrollTop + containerHeight - itemHeight
              ) {
                listContainer.scrollTo({
                  top: targetScrollTop - containerHeight / 2 + itemHeight / 2,
                  behavior: "smooth",
                });
              }
            }
          }
          break;
        case "Escape":
          if (showConfirmation) setShowConfirmation(false);
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [
    products.length,
    currentProductNumber,
    reviewerSelectedMatches,
    showConfirmation,
  ]);

  const handleUpdateMatch = useCallback(async () => {
    try {
      const currentProduct = products[currentProductNumber];
      const matchIndex = reviewerSelectedMatches[currentProductNumber];

      if (matchIndex === -1) return;

      const params = {
        source_product_id: currentProduct?.source_product_id,
        match_product_id: currentProduct?.matches[matchIndex]?.match_product_id,
        status: PRODUCT_DASH_CONST.REVIEW_STATUS.ACCEPT,
        _id: currentProduct?._id,
      };

      await updateMatch(params);

      setProducts((prev: any) => {
        const newProducts = [...prev];
        newProducts[currentProductNumber] = {
          ...newProducts[currentProductNumber],
          tagged_product_id:
            currentProduct?.matches[matchIndex]?.match_product_id,
        };
        return newProducts;
      });

      setSelectedMatch(-1);

      enqueueSnackbar("Match updated successfully", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });

      if (currentProductNumber === products.length - 1) {
        await loadProduct(true);
      } else {
        setCurrentProductNumber((prev) => prev + 1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to update match", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  }, [currentProductNumber, products, reviewerSelectedMatches]);

  const loadProduct = useCallback(async (
    refresh: boolean = false,
    categoryOverride?: string[] | null,
    userOverride?: string | null,
    dateOverride?: [Date | null, Date | null],
    brandOverride?: string[] | null,
    statusOverride?: string | null
  ) => {
    const categoriesToUse = categoryOverride !== undefined ? categoryOverride : selectedCategories;
    const brandsToUse = brandOverride !== undefined ? brandOverride : selectedBrands;
    const userToUse = userOverride !== undefined ? userOverride : selectedUser;
    const dateToUse = dateOverride !== undefined ? dateOverride : selectedDateRange;
    const statusToUse = statusOverride !== undefined ? statusOverride : selectedMatchStatus;
    const hasValidDateRange = dateToUse[0] && dateToUse[1];

    if (!categoriesToUse?.length && !brandsToUse?.length && !userToUse && !hasValidDateRange && !statusToUse) {
      setProducts([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const params: {} = {
        includes: {
          ...(categoriesToUse?.length && { source_category_id: categoriesToUse }),
          ...(brandsToUse?.length && { source_brand: brandsToUse }),
          ...(userToUse && { tagged_by: userToUse }),
          ...(statusToUse && { tag_status: statusToUse }),
          ...(hasValidDateRange && {
            tagged_timestamp: {
              start: dateToUse[0]!.toLocaleDateString("en-CA"),
              end: dateToUse[1]!.toLocaleDateString("en-CA"),
            },
          }),
        },
        refresh,
      };
      const productsBatch: any = await getReviewBatch(params);

      if (!productsBatch.data?.products) {
        setProducts([]);
        setCurrentProductNumber(0);
        setReviewerSelectedMatches([]);
        setSelectedCategories([]);
        setSelectedBrands([]);
        setSelectedUser(null);
        setSelectedDateRange([null, null]);
        setSelectedMatchStatus(null);
        loadFilters();
        return;
      }
      if (productsBatch.data?.products) {
        setProducts(productsBatch.data.products);
        searchNext = productsBatch.data.next_search_after;
        setCurrentProductNumber(0);
        setReviewerSelectedMatches([]);
      }
    } catch (error) {
      setError("Failed to load products for review");
      enqueueSnackbar("Failed to load products", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [selectedCategories, selectedBrands, selectedUser, selectedDateRange, selectedMatchStatus, enqueueSnackbar]);

  const loadFilters = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getReviewFilters({
        includes: {
          ...(selectedCategories?.length && { source_category_id: selectedCategories }),
          ...(selectedBrands?.length && { source_brand: selectedBrands }),
          ...(selectedUser && { tagged_by: selectedUser }),
          ...(selectedMatchStatus && { tag_status: selectedMatchStatus }),
          ...(selectedDateRange[0] && selectedDateRange[1] && {
            tagged_timestamp: {
              start: selectedDateRange[0].toLocaleDateString("en-CA"),
              end: selectedDateRange[1].toLocaleDateString("en-CA"),
            },
          }),
        }
      })

      // Ensure the response matches our FilterData interface
      const formattedResponse: FilterData = {
        source_category_id: { data: response.data.source_category_id || [] },
        source_brand: { data: response.data.source_brand || [] },
        taggers: { data: response.data.taggers || [] },
        date_ranges: response.data.date_ranges || { min: null, max: null },
        tag_status: { data: response.data.tag_status || [] },
      };
      setFilters(formattedResponse);
    } catch (error) {
      console.error("Error loading filters:", error);
      enqueueSnackbar("Failed to load filters", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [selectedCategories, selectedBrands, selectedUser, selectedDateRange, selectedMatchStatus, enqueueSnackbar]);

  useEffect(() => {
    loadFilters();
  }, []);

  const handleNoMatch = async () => {
    try {
      await updateMatch({
        source_product_id: products[currentProductNumber]?.source_product_id,
        match_product_id: "",
        status: PRODUCT_DASH_CONST.REVIEW_STATUS.REJECT,
        _id: products[currentProductNumber]?._id,
      }).then((res) => {
        setReviewerSelectedMatches((prev) => {
          const newMatches = [...prev];
          newMatches[currentProductNumber] = -1;
          return newMatches;
        });
        setProducts((prev: any) => {
          const newProducts = [...prev];
          newProducts[currentProductNumber] = {
            ...newProducts[currentProductNumber],
            tagged_product_id: "",
          };
          return newProducts;
        });
        enqueueSnackbar("Tagged No Match", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      });
      if (currentProductNumber === products.length - 1) {
        await completeCurrentBatch();
        await loadProduct(true);
      } else {
        setCurrentProductNumber((prev) => prev + 1);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to reject product", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  };

  const handleDisable = useCallback(() => {
    return (
      !reviewerSelectedMatches.length ||
      reviewerSelectedMatches[currentProductNumber] === -1 ||
      products[currentProductNumber]?.matches[
        reviewerSelectedMatches[currentProductNumber]
      ]?.match_product_id === products[currentProductNumber]?.tagged_product_id
    );
  }, [reviewerSelectedMatches, products, currentProductNumber]);

  const handleScroll = useCallback(
    async (event: any) => {
      if (!isLoadingMore && searchNext && (selectedCategories?.length || selectedBrands?.length || selectedUser || (selectedDateRange[0] && selectedDateRange[1]) || selectedMatchStatus)) {
        const { scrollDirection, scrollOffset } = event;
        const isNearEnd = products.length - scrollOffset / 80 < 10; // 10 items from end

        if (scrollDirection === "forward" && scrollOffset > 0 && isNearEnd) {
          setIsLoadingMore(true);
          try {
            const params: {} = {
              includes: {
                ...(selectedCategories?.length && { source_category_id: selectedCategories }),
                ...(selectedBrands?.length && { source_brand: selectedBrands }),
                ...(selectedUser && { tagged_by: selectedUser }),
                ...(selectedMatchStatus && { tag_status: selectedMatchStatus }),
                ...(selectedDateRange[0] &&
                  selectedDateRange[1] && {
                  tagged_timestamp: {
                    start: selectedDateRange[0].toLocaleDateString("en-CA"),
                    end: selectedDateRange[1].toLocaleDateString("en-CA"),
                  },
                }),
              },
              search_after: searchNext,
              refresh: false,
            };
            const productsBatch: any = await getReviewBatch(params);
            searchNext = productsBatch?.data?.next_search_after;

            // Update reviewer selected matches array to match new products length
            setReviewerSelectedMatches((prev) => {
              const newMatches = [...prev];
              const additionalMatches = Array(
                productsBatch?.data?.products?.length || 0
              ).fill(-1);
              return [...newMatches, ...additionalMatches];
            });

            setProducts((prev: any) => [
              ...prev,
              ...(productsBatch?.data?.products || []),
            ]);
          } catch (error: any) {
            enqueueSnackbar(error.message || FAILED_API_MSG, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          } finally {
            setIsLoadingMore(false);
          }
        }
      }
    },
    [isLoadingMore, selectedCategories, selectedUser, selectedDateRange, selectedBrands, selectedMatchStatus]
  );

  const Row = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const product = products[index];
      return (
        <div
          style={{
            ...style,
            height: "80px",
            padding: "8px",
            boxSizing: "border-box",
          }}
          key={product.source_product_id}
          className={`product-list-item ${index === currentProductNumber ? "active" : ""
            }`}
          onClick={() => setCurrentProductNumber(index)}
        >
          <img
            src={product.source_feature_image_s3}
            alt={product.source_product_name}
            className="product-thumbnail"
          />
          <div className="product-info">
            <Text
              small
              css={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
              }}
            >
              {product.source_product_name}
            </Text>
            <Text small css={{ color: "$accents7" }}>
              {product.tagged_by || "User"}
            </Text>
          </div>
        </div>
      );
    },
    [currentProductNumber, products]
  );

  useEffect(() => {
    const initializeData = async () => {
      if (!selectedCategories.length && !selectedBrands.length && !selectedDateRange[0] && !selectedDateRange[1] && !selectedUser && !selectedMatchStatus) {
        setProducts([]);
        setCurrentProductNumber(0);
        loadFilters();
        return;
      }

      await Promise.all([
        loadProduct(true),
        loadFilters()
      ]);
    };

    initializeData();
  }, [selectedBrands, selectedCategories, selectedUser, selectedDateRange, selectedMatchStatus, loadProduct, loadFilters]);

  const handleResetFilters = () => {
    setSelectedCategories([]);
    setSelectedBrands([]);
    setSelectedUser(null);
    setSelectedDateRange([null, null]);
    setSelectedMatchStatus(null);
    setCategorySearch("");
    setBrandSearch("");
    loadFilters();
    enqueueSnackbar("Filters reset successfully", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  if (!isReviewer) {
    return <Navigate to="/" replace />;
  }

  if (loading) {
    return <Loading>Loading products...</Loading>;
  }

  if (error) {
    return (
      <Container>
        <Text color="error">{error}</Text>
        <Button onClick={() => loadProduct(true)}>Retry</Button>
      </Container>
    );
  }
  

  return (
    <>
      <div className="dashboard-container">
        <div className="main-content">
          <div className={`sidebar`}>
            <div
              className="review-dashboard-action-buttons"
              style={{ marginBottom: "10px" }}
            >
              <Button
                size="sm"
                color="primary"
                disabled={products.length === 0 || (!selectedCategories.length && !selectedBrands.length && !selectedUser && !selectedDateRange[0] && !selectedDateRange[1] && !selectedMatchStatus)}
                onClick={() => {
                  setBulkAction("approve");
                  setShowBulkConfirmation(true);
                }}
              >
                Approve All
              </Button>
              <Button
                size="sm"
                color="primary"
                bordered
                disabled={products.length === 0 || (!selectedCategories.length && !selectedBrands.length && !selectedUser && !selectedDateRange[0] && !selectedDateRange[1] && !selectedMatchStatus)}
                onClick={() => {
                  setBulkAction("reject");
                  setShowBulkConfirmation(true);
                }}
              >
                Revert Tagging
              </Button>
            </div>
            {products.length ? (
              <div className="review-dashboard-products-queue">
                <Text h4>
                  Products Queue{" "}
                  <span className="shortcut-hint">(↑↓ to navigate)</span>
                </Text>
                <div style={{ height: "calc(100vh - 240px)" }}>
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <List
                        height={height}
                        itemCount={products.length}
                        itemSize={80}
                        width={width}
                        onScroll={handleScroll}
                        className="product-list"
                      >
                        {Row}
                      </List>
                    )}
                  </AutoSizer>
                </div>
                {isLoadingMore && <Loading size="sm">Loading more...</Loading>}
              </div>
            ) : (
              <div>
                <Text>No products available for review</Text>
              </div>
            )}
          </div>

          <div className="review-dashboard-product-matches-container">
            {filters && (
              <div
                className="filter-section"
                style={{
                  padding: "8px 20px",
                  background: "#f5f5f5",
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <div className="filters">
                  <div className="review-category-filter">
                    <div style={{ width: '140px', position: 'relative' }}>
                      <Popover placement="bottom-left">
                        <Popover.Trigger>
                          <Button
                            flat
                            css={{
                              width: '100%',
                              justifyContent: 'space-between',
                              backgroundColor: '#CEE4FE',
                              height: '32px',
                              padding: '8px 12px'
                            }}
                          >
                            {selectedCategories.length > 0
                              ? `${selectedCategories.length} selected`
                              : "Categories"}
                          </Button>
                        </Popover.Trigger>
                        <Popover.Content css={{ minWidth: 'fit-content', maxHeight: '400px', overflow: 'auto' }}>
                          <div style={{ padding: '8px' }}>
                            <Input
                              type="text"
                              value={categorySearch}
                              onChange={(e) => setCategorySearch(e.target.value)}
                              placeholder="Search categories..."
                              css={{ marginBottom: '8px' }}
                              clearable
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                              {filters?.source_category_id?.data
                                ?.filter((category: any) =>
                                  !categorySearch ||
                                  fuzzySearch(categorySearch, category.category_name)
                                )
                                .map((category: any) => (
                                  <Button
                                    key={category.category_id}
                                    flat
                                    size="sm"
                                    css={{
                                      justifyContent: 'flex-start',
                                      backgroundColor: selectedCategories.includes(category.category_id)
                                        ? '$primary'
                                        : 'transparent',
                                      color: selectedCategories.includes(category.category_id)
                                        ? 'white'
                                        : '$text',
                                      '&:hover': {
                                        backgroundColor: selectedCategories.includes(category.category_id)
                                          ? '$primary'
                                          : '$accents1'
                                      },
                                    }}
                                    onClick={() => {
                                      const newSelected = [...selectedCategories];
                                      const categoryId = category.category_id;
                                      if (newSelected.includes(categoryId)) {
                                        newSelected.splice(newSelected.indexOf(categoryId), 1);
                                      } else {
                                        newSelected.push(categoryId);
                                      }
                                      setSelectedCategories(newSelected);
                                      loadProduct(true, newSelected, selectedUser, selectedDateRange, selectedBrands);
                                    }}
                                  >
                                    {formatCategoryName(category.category_name)}
                                  </Button>
                                ))}
                            </div>
                          </div>
                        </Popover.Content>
                      </Popover>
                    </div>
                  </div>
                  <div className="brand-filter">
                    <div style={{ width: '140px', position: 'relative' }}>
                      <Popover placement="bottom-left">
                        <Popover.Trigger>
                          <Button
                            flat
                            css={{
                              width: '100%',
                              justifyContent: 'space-between',
                              backgroundColor: '#CEE4FE',
                              height: '32px',
                              padding: '8px 12px',
                            }}
                          >
                            {selectedBrands.length > 0
                              ? `${selectedBrands.length} selected`
                              : "Brands"}
                          </Button>
                        </Popover.Trigger>
                        <Popover.Content css={{ minWidth: 'fit-content', maxHeight: '400px', overflow: 'auto' }}>
                          <div style={{ padding: '8px' }}>
                            <Input
                              type="text"
                              value={brandSearch}
                              onChange={(e) => setBrandSearch(e.target.value)}
                              placeholder="Search brands..."
                              css={{ marginBottom: '8px' }}
                              clearable
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                              {filters?.source_brand?.data
                                ?.filter((brand: any) =>
                                  !brandSearch ||
                                  fuzzySearch(brandSearch, brand.brand_name)
                                )
                                .map((brand: any) => (
                                  <Button
                                    key={brand.brand_id}
                                    flat
                                    size="sm"
                                    css={{
                                      justifyContent: 'flex-start',
                                      backgroundColor: selectedBrands.includes(brand.brand_id)
                                        ? '$primary'
                                        : 'transparent',
                                      color: selectedBrands.includes(brand.brand_id)
                                        ? 'white'
                                        : '$text',
                                      '&:hover': {
                                        backgroundColor: selectedBrands.includes(brand.brand_id)
                                          ? '$primary'
                                          : '$accents1'
                                      },
                                    }}
                                    onClick={() => {
                                      const newSelected = [...selectedBrands];
                                      const brandId = brand.brand_id;
                                      if (newSelected.includes(brandId)) {
                                        newSelected.splice(newSelected.indexOf(brandId), 1);
                                      } else {
                                        newSelected.push(brandId);
                                      }
                                      setSelectedBrands(newSelected);
                                      loadProduct(true, selectedCategories, selectedUser, selectedDateRange, newSelected);
                                    }}
                                  >
                                    {brand.brand_name}
                                  </Button>
                                ))}
                            </div>
                          </div>
                        </Popover.Content>
                      </Popover>
                    </div>
                  </div>
                  <div className="user-filter">
                    <div style={{ width: '140px', position: 'relative' }}>
                    <Dropdown>
                      <Dropdown.Button flat
                        css={{
                          width: '100%',
                          justifyContent: 'space-between',
                          backgroundColor: '#CEE4FE',
                          height: '32px',
                          padding: '8px 12px',
                        }}
                      >
                        {selectedUser || "Select User"}
                      </Dropdown.Button>
                      <Dropdown.Menu
                        aria-label="User selection"
                        selectedKeys={selectedUser ? [selectedUser] : []}
                        selectionMode="single"
                        onAction={(key: any) => {
                          if (key === "clear") {
                            setSelectedUser(null);
                          } else {
                            setSelectedUser(key as string);
                          }
                        }}
                        css={{
                          $$dropdownMenuWidth: "200px",
                          maxHeight: "300px",
                          overflowY: "auto",
                          padding: "6px",
                          "& .nextui-dropdown-item": {
                            fontSize: "12px",
                            height: "32px",
                            padding: "6px 8px",
                          },
                          "& .nextui-dropdown-item-content": {
                            fontSize: "12px",
                          }
                        }}
                      >
                        {selectedUser && <Dropdown.Item key="clear" css={{ color: "$error" }}>
                          Clear Selection
                        </Dropdown.Item>}
                        {filters.taggers?.data.map((user: any) => {
                          return (
                            <Dropdown.Item key={user.user_id}>
                              {user.user_id}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                  </div>
                  <div className="match-status-filter">
                    <div style={{ width: '140px', position: 'relative' }}>
                      <Dropdown>
                        <Dropdown.Button
                          flat
                          css={{
                            width: '100%',
                            justifyContent: 'space-between',
                            backgroundColor: '#CEE4FE',
                            height: '32px',
                            padding: '8px 12px',
                          }}
                        >
                          {selectedMatchStatus ? formatCategoryName(selectedMatchStatus) : "Match Status"}
                        </Dropdown.Button>
                        <Dropdown.Menu
                          aria-label="Match status selection"
                          selectedKeys={selectedMatchStatus ? [selectedMatchStatus] : []}
                          selectionMode="single"
                          css={{
                            minWidth: 'fit-content',
                            $$dropdownMenuWidth: "200px",
                            maxHeight: "300px",
                            overflowY: "auto",
                            padding: "6px",
                            "& .nextui-dropdown-item": {
                              fontSize: "12px",
                              height: "32px",
                              padding: "6px 8px",
                            },
                            "& .nextui-dropdown-item-content": {
                              fontSize: "12px",
                            }
                          }}
                          onAction={(key: any) => {
                            if (key === "clear") {
                              setSelectedMatchStatus(null);
                            } else {
                              setSelectedMatchStatus(key as string);
                            }
                            if(document.activeElement instanceof HTMLElement) {
                              document.activeElement.blur();
                            }
                          }}
                        >
                          {selectedMatchStatus && (
                            <Dropdown.Item key="clear" css={{ color: "$error" }}>
                              Clear Selection
                            </Dropdown.Item>
                          )}
                          {filters?.tag_status?.data?.map((status: any) => (
                            <Dropdown.Item key={status.tag_status}>
                              {formatCategoryName(status.tag_status)}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="date-filter">
                    {filters?.date_ranges && (
                      <Popover
                        isOpen={isDatePopoverOpen}
                        onOpenChange={setIsDatePopoverOpen}
                        placement="bottom"
                      >
                        <Popover.Trigger>
                          <Button flat
                            css={{
                              width: '100%',
                              justifyContent: 'space-between',
                              backgroundColor: '#CEE4FE',
                              height: '32px',
                              padding: '8px 12px'
                            }}
                          >
                            {selectedDateRange[0] && selectedDateRange[1]
                              ? `${selectedDateRange[0].toLocaleDateString()} - ${selectedDateRange[1].toLocaleDateString()}`
                              : "Date Range"}
                          </Button>
                        </Popover.Trigger>
                        <Popover.Content css={{ minWidth: 'fit-content', maxHeight: '400px', overflow: 'auto' }}>
                          <div style={{ padding: "1rem" }}>
                            <DatePicker
                              type="range"
                              minDate={minDate ? minDate : undefined}
                              maxDate={maxDate ? maxDate : undefined}
                              value={selectedDateRange}
                              onChange={(dates) => {
                                if (Array.isArray(dates)) {
                                  const [start, end] = dates;
                                  setSelectedDateRange([start, end]);
                                  if (start && end) {
                                    setIsDatePopoverOpen(false);
                                    loadProduct(true, undefined, undefined, [
                                      start,
                                      end,
                                    ]);
                                  }
                                }
                              }}
                            />
                            {selectedDateRange[0] && selectedDateRange[1] && (
                              <Button
                                size="sm"
                                color="error"
                                light
                                css={{ marginTop: "8px" }}
                                onClick={() => {
                                  setSelectedDateRange([null, null]);
                                  setIsDatePopoverOpen(false);
                                  loadProduct(true, undefined, undefined, [
                                    null,
                                    null,
                                  ]);
                                }}
                              >
                                Clear Dates
                              </Button>
                            )}
                          </div>
                        </Popover.Content>
                      </Popover>
                    )}
                  </div>
                  <div className="reset-button-container">
                    {(selectedCategories.length || selectedBrands.length || selectedUser || (selectedDateRange[0] !== null && selectedDateRange[1] !== null) || selectedMatchStatus) && (
                      <Button
                        flat
                        color="error"
                        size="md"
                        className="reset-button"
                        onClick={handleResetFilters}
                        css={{ minWidth: 'auto' }}
                      >
                        Reset
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {products.length ? (
              <div className="reivew-dashboard-product-display-section">
                <div className="source-product">
                  <Text h4>Product Under Review</Text>
                  <ProductCard
                    imgURL={
                      products[currentProductNumber]?.source_feature_image_s3
                    }
                    name={products[currentProductNumber]?.source_product_name}
                    mrp={products[currentProductNumber]?.source_mrp}
                    pdpURL={products[currentProductNumber]?.source_pdp_url}
                    brand={products[currentProductNumber]?.source_brand}
                  />
                  {!products[currentProductNumber]?.tagged_product_id && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff0f1",
                        border: "1px solid #fdd1d7",
                        borderRadius: "4px",
                        marginTop: "8px",
                      }}
                    >
                      <Text css={{ color: "#F31260", margin: "0px" }}>
                        No Match found for this product
                      </Text>
                    </div>
                  )}
                </div>

                <div className="matches-container">
                  {products[currentProductNumber]?.matches.map(
                    (match: any, index: number) => {
                      return (
                        <div
                          key={match.match_product_id}
                          className="match-item"
                        >
                          <ProductCard
                            imgURL={match.match_feature_image_s3}
                            name={match.match_product_name}
                            mrp={match.match_mrp}
                            pdpURL={match.match_pdp_url}
                            brand={match.match_brand}
                            highlighted={
                              reviewerSelectedMatches.length &&
                                reviewerSelectedMatches[currentProductNumber] !==
                                -1
                                ? products[currentProductNumber]?.matches[
                                  reviewerSelectedMatches[
                                  currentProductNumber
                                  ]
                                ].match_product_id === match.match_product_id
                                : products[currentProductNumber]
                                  ?.tagged_product_id ===
                                match.match_product_id
                            }
                            serialNumber={index + 1}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
                <div className={`bottom-action-bar`}>
                  <div className="action-buttons">
                    <Button color="error" onClick={handleNoMatch}>
                      No Match Found <span className="shortcut-hint">(0)</span>
                    </Button>
                    <Button
                      color="primary"
                      disabled={handleDisable()}
                      onClick={handleDisable() ? () => { } : handleUpdateMatch}
                    >
                      Update <span className="shortcut-hint">(Enter)</span>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '50px',
                justifyContent: 'center', 
                padding: '20px' 
              }}>
                <Text css={{ fontSize: '24px', color: '#666' }}>Select filters to start reviewing</Text>
              </div>
            )}
          </div>
        </div>
      </div>
      {showConfirmation && (
        <NextModal
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          aria-labelledby="confirmation-modal"
          className="confirmation-modal"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Text h4>Confirmation</Text>
            </div>
            <div className="modal-body">
              <Text>
                Please save the changes before proceeding to the next product
              </Text>
            </div>
            <div className="modal-footer">
              <Button
                auto
                flat
                css={{
                  backgroundColor: "#f5f5f5",
                  color: "grey",
                }}
                onPress={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                auto
                color="primary"
                onPress={async () => {
                  await handleUpdateMatch();
                  setShowConfirmation(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </NextModal>
      )}
      {showBulkConfimation && (
        <NextModal
          open={showBulkConfimation}
          onClose={() => setShowBulkConfirmation(false)}
          aria-labelledby="confirmation-modal"
          className="confirmation-modal"
        >
          <div className="modal-content">
            <div className="modal-header">
              <Text h4>Confirmation</Text>
            </div>
            <div className="modal-body">
              <Text>
                Are you sure you want to {bulkAction} all products
                in selected filters ?
              </Text>
            </div>
            <div className="modal-footer">
              <Button
                auto
                flat
                css={{
                  backgroundColor: "#f5f5f5",
                  color: "grey",
                }}
                bordered={false}
                onPress={() => {
                  setShowBulkConfirmation(false);
                }}
              >
                Cancel
              </Button>
              <Button
                auto
                color={bulkAction === "approve" ? "primary" : "error"}
                onPress={async () => {
                  if (bulkAction === "approve") {
                    await handleApproveAll();
                  } else {
                    await handleRevertTagging();
                  }
                  setShowBulkConfirmation(false);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </NextModal>
      )}
    </>
  );
});

export default ReviewDashboard;
