import React, { useState, useEffect, useCallback } from 'react';
import { Table, Text, Loading, Dropdown, Popover, Button, Input } from '@nextui-org/react';
import { get_user_analytics, get_category_analytics, get_overall_analytics, get_brand_analytics, get_analytics_tagger_filters } from '../api/dashboard';
import { formatCategoryName, fuzzySearch } from "../utils/helpers";
import { DatePicker } from '@mantine/dates';
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "../styles/analytics.css";
import { useSnackbar } from "notistack";
import { useAuth } from "../context/AuthContext";
import { Navigate } from 'react-router-dom';


interface TaggerData {
  user: string;
  productsTagged: number;
  matched: number;
  not_matched: number;
}

interface CategoryData {
  category: string;
  category_id: number,
  totalTagged: number;
  totalMatched: number;
  totalUnmatched: number;
  taggingPending: number;
}

interface DatePickerProps {
  selected: Date | undefined;
  onChange: (date: Date | null) => void;
  placeholderText?: string;
  className?: string;
  maxDate?: Date;
  minDate?: Date;
}

interface OverallData {
  totalTagged: number;
  totalMatched: number;
  totalUnmatched: number;
  totalPending: number;
}

interface BrandData {
  brand_name: string;
  totalTagged: number;
  totalMatched: number;
  totalUnmatched: number;
}

// Add these new types
type SortDescriptor = {
  column: string;
  direction: 'ascending' | 'descending';
};


const AnalyticsDashboard: React.FC = () => {
  const { user } = useAuth();
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [taggerLoading, setTaggerLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [brandData, setBrandData] = useState<BrandData[]>([]);
  const [taggerData, setTaggerData] = useState<TaggerData[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(new Set());
  const [selectedBrands, setSelectedBrands] = useState<Set<string>>(new Set());
  const { enqueueSnackbar } = useSnackbar();
  const [isDatePopoverOpen, setIsDatePopoverOpen] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [overallData, setOverallData] = useState<OverallData | null>(null);
  const [globalDateRange, setGlobalDateRange] = useState<[Date | null, Date | null]>([null, null]);

  // Add new sorting states
  const [taggerSort, setTaggerSort] = useState<SortDescriptor>({
    column: '',
    direction: 'descending'
  });
  const [categorySort, setCategorySort] = useState<SortDescriptor>({
    column: '',
    direction: 'descending'
  });
  const [brandSort, setBrandSort] = useState<SortDescriptor>({
    column: '',
    direction: 'descending'
  });
  // Add new loading state for overall refresh
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isReviewer = user?.role?.includes("reviewer") || user?.role?.includes("admin");
  const [categorySearch, setCategorySearch] = useState("");
  const [brandSearch, setBrandSearch] = useState("");
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] = useState(false);
  const [taggerFilters, setTaggerFilters] = useState<any>({});

  const sortData = <T extends object>(
    data: T[],
    column: keyof T,
    direction: 'ascending' | 'descending'
  ): T[] => {
    return [...data].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle string comparisons (ascending by default)
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return direction === 'ascending'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      // Handle numeric comparisons (descending by default)
      return direction === 'ascending'
        ? Number(aValue) - Number(bValue)
        : Number(bValue) - Number(aValue);
    });
  };


  // Default descending for numbers
  const hasActiveFilters = () => {
    return (
      selectedCategories.size > 0 ||
      selectedBrands.size > 0
    );
  };

  const handleReset = async () => {
    // Reset only category and brand filters, keep date range
    setSelectedCategories(new Set());
    setSelectedBrands(new Set());
    setCategorySearch("");
    setBrandSearch("");
    await refreshData();
    enqueueSnackbar("Filters reset", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  const handleResetAll = async () => {
    // Reset everything including date range
    setSelectedCategories(new Set());
    setSelectedBrands(new Set());
    setIsDatePopoverOpen(false);
    setGlobalDateRange([null, null]);
    await refreshData();
    enqueueSnackbar("All filters reset", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  // Add initial load
  useEffect(() => {
    refreshData();
  }, []); // Initial load only

  const refreshData = useCallback(async () => {
    setIsRefreshing(true);
    setCategoryLoading(true);
    setTaggerLoading(true);
    setBrandLoading(true);
    try {
      // Create date params for global filtering
      const dateParams = globalDateRange[0] && globalDateRange[1] ? {
        tagged_timestamp: {
          start: globalDateRange[0].toLocaleDateString("en-CA"),
          end: globalDateRange[1].toLocaleDateString("en-CA"),
        },
      } : {};

      // Create separate params for analytics
      const globalParams = {
        includes: dateParams
      };

      // Create params for tagger analytics
      const taggerParams = {
        includes: {
          ...dateParams,
          ...(selectedCategories.size > 0 && {
            source_category_id: Array.from(selectedCategories).map(Number)
          }),
          ...(selectedBrands.size > 0 && {
            source_brand: Array.from(selectedBrands)
          })
        }
      };

      // Make API calls with appropriate params
      const [overallRes, categoryRes, taggerRes, brandRes] = await Promise.all([
        get_overall_analytics(globalParams),
        get_category_analytics(globalParams),
        get_user_analytics(taggerParams),
        get_brand_analytics(globalParams)
      ]);

      // Update states with new data
      if (overallRes?.data?.overall_analytics) {
        setOverallData({
          totalTagged: overallRes.data.overall_analytics.totalTagged || 0,
          totalMatched: overallRes.data.overall_analytics.totalMatched || 0,
          totalUnmatched: overallRes.data.overall_analytics.totalUnmatched || 0,
          totalPending: overallRes.data.overall_analytics.taggingPending || 0,
        });
      }

      if (categoryRes?.data?.category_board) {
        setCategoryData(categoryRes.data.category_board.map((item: any) => ({
          category: item.category,
          category_id: item.category_id,
          totalTagged: item.totalTagged,
          totalMatched: item.totalMatched,
          totalUnmatched: item.totalUnmatched,
          taggingPending: item.taggingPending
        })));
      }

      if (taggerRes?.data) {
        setTaggerData(taggerRes.data.leaderboard.map((item: any) => ({
          user: item.user,
          productsTagged: item.total,
          matched: item.matched,
          not_matched: item.not_matched
        })));
      }

      if (brandRes?.data?.brand_board) {
        setBrandData(brandRes.data.brand_board.map((item: any) => ({
          brand_name: item.brand_name,
          totalTagged: item.totalTagged,
          totalMatched: item.totalMatched,
          totalUnmatched: item.totalUnmatched
        })));
      }

    } catch (error: any) {
      enqueueSnackbar(error.message || "Failed to refresh data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      setCategoryLoading(false);
      setTaggerLoading(false);
      setBrandLoading(false);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 500);
    }
  }, [selectedCategories, selectedBrands, globalDateRange, enqueueSnackbar]);

  // Update useEffect for filter changes
  useEffect(() => {
    refreshData();
  }, [selectedCategories, globalDateRange, enqueueSnackbar, selectedBrands]);

  const handleGlobalDateChange = async (dates: [Date | null, Date | null]) => {
    // Validate date range
    if (dates[0] && dates[1] && dates[1] < dates[0]) {
      enqueueSnackbar("End date cannot be before start date", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    // Reset category and brand selections when applying date range
    setSelectedCategories(new Set());
    setSelectedBrands(new Set());
    setGlobalDateRange(dates);
    setBrandSearch("");
    setCategorySearch("");

    if (dates[0] && dates[1]) {
      setIsDatePopoverOpen(false);
      await refreshData();
      enqueueSnackbar("Date range applied and filters reset", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  };

  const handleResetDates = async () => {
    setGlobalDateRange([null, null]);
    setBrandSearch("")
    setCategorySearch("")
    await refreshData();
    enqueueSnackbar("Date filter reset", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  const loadTaggerFilters = useCallback(async () => {
    try {
      const response = await get_analytics_tagger_filters({
        includes: {
          ...(globalDateRange[0] && globalDateRange[1] && {
            tagged_timestamp: {
              start: globalDateRange[0].toISOString().split('T')[0],
              end: globalDateRange[1].toISOString().split('T')[0],
            }
          }),
          ...(selectedCategories.size > 0 && {
            source_category_id: Array.from(selectedCategories).map(Number)
          }),
          ...(selectedBrands.size > 0 && {
            source_brand: Array.from(selectedBrands)
          })
        }
      });
      setTaggerFilters(response.data);
    } catch (error) {
      console.error("Error loading tagger filters:", error);
      enqueueSnackbar("Failed to load tagger filters", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [selectedCategories, selectedBrands, globalDateRange, enqueueSnackbar]);


  useEffect(() => {
    // Set up auto-refresh interval
    const refreshInterval = setInterval(() => {
      refreshData();
      loadTaggerFilters();
    }, 15000); // 15 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(refreshInterval);
  }, [refreshData, loadTaggerFilters]);

  useEffect(() => {
    loadTaggerFilters();
  }, [loadTaggerFilters]);

  if (!isReviewer) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="analytics-container">
      {isRefreshing && <div className="global-refresh-loader" />}
      {/* Global Date Filter */}
      <div className="global-filters">
        <Text h4>Filters</Text>
        <div className="date-filter-container">
          <Popover
            isOpen={isDatePopoverOpen}
            onOpenChange={setIsDatePopoverOpen}
            placement="bottom"
          >
            <Popover.Trigger>
              <Button flat>
                {globalDateRange[0] && globalDateRange[1]
                  ? `${globalDateRange[0].toLocaleDateString()} - ${globalDateRange[1].toLocaleDateString()}`
                  : "Date Range"}
              </Button>
            </Popover.Trigger>
            <Popover.Content>
              <div className="date-picker-container">
                <DatePicker
                  type="range"
                  value={globalDateRange}
                  onChange={(dates) => handleGlobalDateChange(dates as [Date | null, Date | null])}
                />
              </div>
            </Popover.Content>
          </Popover>

          {globalDateRange[0] && globalDateRange[1] && (
            <Button
              flat
              color="error"
              onClick={handleResetDates}
            >
              Reset Dates
            </Button>
          )}
        </div>
      </div>
      {/* Overall Analytics Table */}
      <div className="table-section">
        <Text h4>Overall Analytics</Text>
        {overallLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading overall data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Overall analytics table"
            css={{ minWidth: "100%" }}
          >
            <Table.Header>
              <Table.Column>Tagged</Table.Column>
              <Table.Column>Matched</Table.Column>
              <Table.Column>Not Matched</Table.Column>
              <Table.Column>Tagging Pending</Table.Column>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{overallData?.totalTagged || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalMatched || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalUnmatched || 0}</Table.Cell>
                <Table.Cell>{overallData?.totalPending || 0}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </div>
      {/* Tagger Analytics Section */}
      <div className="table-section">
        <Text h4>Tagger Overview</Text>
        <div className="filters-container">
          <div className="filters-left">
            <div style={{ width: '220px', position: 'relative' }}>
              <Popover placement="bottom-left">
                <Popover.Trigger>
                  <Button
                    flat
                    css={{
                      width: '100%',
                      justifyContent: 'space-between',
                      backgroundColor: '#CEE4FE',
                      height: '40px'
                    }}
                  >
                    {selectedCategories.size > 0
                      ? `${selectedCategories.size} categories selected`
                      : "Categories"}
                  </Button>
                </Popover.Trigger>
                <Popover.Content css={{ width: '220px', maxHeight: '400px', overflow: 'auto' }}>
                  <div style={{ padding: '8px' }}>
                    <Input
                      type="text"
                      value={categorySearch}
                      onChange={(e) => setCategorySearch(e.target.value)}
                      placeholder="Search categories..."
                      css={{ marginBottom: '8px' }}
                      clearable
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      {taggerFilters?.source_category_id
                        ?.filter((category: any) =>
                          !categorySearch ||
                          fuzzySearch(categorySearch, category.category_name)
                        )
                        .map((category: any) => (
                          <Button
                            key={category.category_id}
                            flat
                            size="sm"
                            css={{
                              justifyContent: 'flex-start',
                              backgroundColor: selectedCategories.has(String(category.category_id))
                                ? '$primary'
                                : 'transparent',
                              color: selectedCategories.has(String(category.category_id))
                                ? 'white'
                                : '$text',
                            }}
                            onClick={() => {
                              const newSelected = new Set(selectedCategories);
                              if (newSelected.has(String(category.category_id))) {
                                newSelected.delete(String(category.category_id));
                              } else {
                                newSelected.add(String(category.category_id));
                              }
                              setSelectedCategories(newSelected);
                            }}
                          >
                            {category.category_name ? formatCategoryName(category.category_name) : 'Unknown Category'}
                          </Button>
                        ))}
                    </div>
                    {taggerFilters?.source_category_id?.length === 0 && (
                      <Text css={{ color: '$accents6', textAlign: 'center', padding: '8px' }}>
                        No categories found
                      </Text>
                    )}
                  </div>
                </Popover.Content>
              </Popover>
            </div>
            {/* Brand Search and Dropdown */}
            <div style={{ width: '220px', position: 'relative' }}>
              <Popover placement="bottom-left">
                <Popover.Trigger>
                  <Button
                    flat
                    css={{
                      width: '100%',
                      justifyContent: 'space-between',
                      backgroundColor: '#CEE4FE',
                      height: '40px'
                    }}
                  >
                    {selectedBrands.size > 0
                      ? `${selectedBrands.size} brands selected`
                      : "Brands"}
                  </Button>
                </Popover.Trigger>
                <Popover.Content css={{ width: '220px', maxHeight: '400px', overflow: 'auto' }}>
                  <div style={{ padding: '8px' }}>
                    <Input
                      type="text"
                      value={brandSearch}
                      onChange={(e) => setBrandSearch(e.target.value)}
                      placeholder="Search brands..."
                      css={{ marginBottom: '8px' }}
                      clearable
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      {taggerFilters?.source_brand
                        ?.filter((brand: any) =>
                          !brandSearch ||
                          fuzzySearch(brandSearch, brand.brand_name)
                        )
                        .map((brand: any) => (
                          <Button
                            key={brand.brand_id}
                            flat
                            size="sm"
                            css={{
                              justifyContent: 'flex-start',
                              backgroundColor: selectedBrands.has(String(brand.brand_id))
                                ? '$primary'
                                : 'transparent',
                              color: selectedBrands.has(String(brand.brand_id))
                                ? 'white'
                                : '$text',
                              '&:hover': {
                                backgroundColor: selectedBrands.has(String(brand.brand_id))
                                  ? '$primary'
                                  : '$accents1'
                              },
                            }}
                            onClick={() => {
                              const newSelected = new Set(selectedBrands);
                              if (newSelected.has(String(brand.brand_id))) {
                                newSelected.delete(String(brand.brand_id));
                              } else {
                                newSelected.add(String(brand.brand_id));
                              }
                              setSelectedBrands(newSelected);
                            }}
                          >
                            {brand.brand_name || 'Unknown Brand'}
                          </Button>
                        ))}
                    </div>
                    {taggerFilters?.source_brand?.length === 0 && (
                      <Text css={{ color: '$accents6', textAlign: 'center', padding: '8px' }}>
                        No brands found
                      </Text>
                    )}
                  </div>
                </Popover.Content>
              </Popover>
            </div>
          </div>

          <div className="filters-right">
            {hasActiveFilters() && (
              <Button
                flat
                color="error"
                onClick={handleReset}
              >
                Reset Filters
              </Button>
            )}
          </div>
        </div>
        {taggerLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading tagger data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Tagger analytics table"
            css={{ minWidth: "100%" }}
            sortDescriptor={taggerSort}
            onSortChange={(descriptor) => {
              const newDescriptor = descriptor as SortDescriptor;
              // If it's a new column, default to descending for numbers
              if (newDescriptor.column !== taggerSort.column) {
                const isNumericColumn = ['productsTagged', 'matched', 'not_matched'].includes(newDescriptor.column);
                newDescriptor.direction = isNumericColumn ? 'descending' : 'ascending';
              }
              setTaggerSort(newDescriptor);
            }}
          >
            <Table.Header>
              <Table.Column key="user" allowsSorting>User</Table.Column>
              <Table.Column allowsSorting key="productsTagged">Tagged</Table.Column>
              <Table.Column allowsSorting key="matched">Matched</Table.Column>
              <Table.Column allowsSorting key="not_matched">Not Matched</Table.Column>
            </Table.Header>
            <Table.Body>
              {sortData(
                taggerData,
                taggerSort.column as keyof TaggerData,
                taggerSort.direction
              ).map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{item.user}</Table.Cell>
                  <Table.Cell>{item.productsTagged}</Table.Cell>
                  <Table.Cell>{item.matched}</Table.Cell>
                  <Table.Cell>{item.not_matched}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      {/* Category Overview Table */}
      <div className="table-section">
        <Text h4>Category Overview</Text>
        {categoryLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading category data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Category analytics table"
            css={{ minWidth: "100%" }}
            sortDescriptor={categorySort}
            onSortChange={(descriptor) => {
              const newDescriptor = descriptor as SortDescriptor;
              // If it's a new column, default to descending for numbers
              if (newDescriptor.column !== categorySort.column) {
                const isNumericColumn = ['totalTagged', 'totalMatched', 'totalUnmatched', 'taggingPending'].includes(newDescriptor.column);
                newDescriptor.direction = isNumericColumn ? 'descending' : 'ascending';
              }
              setCategorySort(newDescriptor);
            }}
          >
            <Table.Header>
              <Table.Column allowsSorting key="category">Category</Table.Column>
              <Table.Column allowsSorting key="totalTagged">Tagged</Table.Column>
              <Table.Column allowsSorting key="totalMatched">Matched</Table.Column>
              <Table.Column allowsSorting key="totalUnmatched">Not Matched</Table.Column>
              <Table.Column allowsSorting key="taggingPending">Pending</Table.Column>
            </Table.Header>
            <Table.Body>
              {sortData(
                categoryData,
                categorySort.column as keyof CategoryData,
                categorySort.direction
              ).map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{formatCategoryName(item.category)}</Table.Cell>
                  <Table.Cell>{item.totalTagged}</Table.Cell>
                  <Table.Cell>{item.totalMatched}</Table.Cell>
                  <Table.Cell>{item.totalUnmatched}</Table.Cell>
                  <Table.Cell>{item.taggingPending}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      {/* Brand Overview Table */}
      <div className="table-section">
        <Text h4>Brand Overview</Text>
        {brandLoading ? (
          <div className="table-loading-container">
            <Loading size="lg" />
            <Text css={{ color: "$accents6" }}>Loading brand data...</Text>
          </div>
        ) : (
          <Table
            aria-label="Brand analytics table"
            css={{ minWidth: "100%" }}
            sortDescriptor={brandSort}
            onSortChange={(descriptor) => {
              const newDescriptor = descriptor as SortDescriptor;
              if (newDescriptor.column !== brandSort.column) {
                const isNumericColumn = ['totalTagged', 'totalMatched', 'totalUnmatched'].includes(newDescriptor.column);
                newDescriptor.direction = isNumericColumn ? 'descending' : 'ascending';
              }
              setBrandSort(newDescriptor);
            }}
          >
            <Table.Header>
              <Table.Column allowsSorting key="brand_name">Brand</Table.Column>
              <Table.Column allowsSorting key="totalTagged">Tagged</Table.Column>
              <Table.Column allowsSorting key="totalMatched">Matched</Table.Column>
              <Table.Column allowsSorting key="totalUnmatched">Not Matched</Table.Column>
            </Table.Header>
            <Table.Body>
              {sortData(
                brandData,
                brandSort.column as keyof BrandData,
                brandSort.direction
              ).map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{item.brand_name}</Table.Cell>
                  <Table.Cell>{item.totalTagged}</Table.Cell>
                  <Table.Cell>{item.totalMatched}</Table.Cell>
                  <Table.Cell>{item.totalUnmatched}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AnalyticsDashboard; 